import axios from 'axios';

class GuestsServices {

  static getGuestsInfo(name) {
    let url = process.env.REACT_APP_BACKEND_URL + '/guests?name=' + name;
    return axios.get(url).then((response) => 
      {
        return response.data;
      })
      .catch((error) => {
        throw error;
      }
    );
  }

  static sendAttendance(data) {
    let url = process.env.REACT_APP_BACKEND_URL + '/guests';
    return axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data;
    })
    .catch(() => {
      return false;
    });
  }
}

export default GuestsServices;