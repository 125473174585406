import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider
} from '@mui/material';

class ErrorDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      form_button: {
        margin: 2,
        borderRadius: 2,
        height:50,
        width: 170,
        boxShadow: 5
      }
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  openDialog() {
    this.setState({ open: true });
  }

  closeDialog() {
    this.setState({ open: false });
  }

  render() {
    return(
    	<>
        <Dialog
          open={ this.state.open }
          onClose={ (e) => this.closeDialog() }
          aria-labelledby='dialog-title'
          aria-describedby='dialog-body'
          scroll='paper'
          PaperProps={{
            sx: {
              height: '70%',
              width: '80%'
            }
          }}
        >
          <DialogTitle id='dialog-title' sx={{ textAlign: 'center' }}>
            <Typography variant='error_title'>
              エラー
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography variant='error_body'>{ this.props.error_message }</Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }} sx={{ mb: 5 }}>
            <Button
              variant='form_forward_button'
              size='large'
              onClick={ () => this.closeDialog() }
              sx={{
                mx: this.state.form_button.margin,
                borderRadius: this.state.form_button.borderRadius,
                height: this.state.form_button.height,
                width: this.state.form_button.width,
                boxShadow: this.state.form_button.boxShadow
              }}
            >
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
			</>
    );
  };
}

export default ErrorDialog;