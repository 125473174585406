import React, { useState, useEffect } from 'react'

import LoginPage from '../components/login/loginPage';
import LoginServices from "../services/loginServices";
import LoadingPage from '../components/loadingPage/loadingPage';

function PrivateRoute({ children }) {
	const [login_state, setLoginState] = useState('loading');

	useEffect(() => {
		try {
			if(localStorage.getItem('token') === null) {
				setLoginState(false);
			} else {
				LoginServices.isLogin({ 'token': localStorage.getItem('token') }).then(response => {
					setLoginState(response);
				});
			}
		} catch(e) {
			setLoginState(false);
		}
	}, []);

	return(
		<>
			{
				login_state === 'loading' 
					? <LoadingPage />
					: login_state
							? <>{ children }</>
							: <LoginPage />
			}
		</>
	);
}

export default PrivateRoute;