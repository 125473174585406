import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  List,
  ListItemText,
  Typography,
  Box
} from '@mui/material';

class ShowanswerDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      form_button: {
        margin: 2,
        borderRadius: 2,
        height:50,
        width: 170,
        boxShadow: 5
      }
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  openDialog() {
    this.setState({ open: true });
  }

  closeDialog() {
    this.setState({ open: false });
  }

  render() {
    return(
    	<>
        {
          this.props.answer.hasOwnProperty('is_answer') ? 
            <Dialog
              open={ this.state.open }
              onClose={ (e) => this.closeDialog() }
              aria-labelledby='dialog-title'
              aria-describedby='dialog-body'
              scroll='paper'
              PaperProps={{
                sx: {
                  height: 'auto',
                  width: '80%'
                }
              }}
            >
              <DialogTitle id='dialog-title' sx={{ textAlign: 'center' }}>
                <Typography variant='attendance_dialog_title'>
                  回答内容
                </Typography>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <Box sx={{ pl: 2 }}>
                  <List>
                    <ListItemText>
                      <Typography variant='attendance_dialog_body' align='left'>
                        出欠席:　{ this.props.answer.attendance.BOOL ? 'はい' : 'いいえ' }
                      </Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography variant='attendance_dialog_body' align='left'>
                        アレルギー有無:　{ this.props.answer.allergy.BOOL ? 'はい' : 'いいえ' }
                      </Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography variant='attendance_dialog_body' align='left'>
                        { this.props.answer.allergy.BOOL ? `アレルギー内容:　${this.props.answer.allergy_content.S}` : '' }
                      </Typography>
                    </ListItemText>
                  </List>
                </Box>
              </DialogContent>
              <DialogActions style={{ justifyContent: 'center' }} sx={{ mb: 2 }}>
                <Button
                  variant='form_forward_button'
                  size='large'
                  onClick={ () => this.closeDialog() }
                  sx={{
                    mx: this.state.form_button.margin,
                    borderRadius: this.state.form_button.borderRadius,
                    height: this.state.form_button.height,
                    width: this.state.form_button.width,
                    boxShadow: this.state.form_button.boxShadow
                  }}
                >
                  閉じる
                </Button>
              </DialogActions>
            </Dialog>
          : <Box></Box>
        }
      </>        
    );
  };
}

export default ShowanswerDialog;