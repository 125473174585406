import React from 'react';
import {
  CircularProgress,
  Grid
} from '@mui/material';

class LoadingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      size: 100
    };
  }

  render() {
    return(
    	<>
        <Grid container direction='column' alignItems='center' justifyContent='center' sx={{ pt: 20 }}>
          <Grid item>
            <CircularProgress size={ this.state.size }/>
          </Grid>
        </Grid>
			</>
    );
  };
}

export default LoadingPage;