import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import MainPage from './components/mainPage/mainPage';
import AccessPage from './components/accessPage/accessPage';
import PrivateRoute from './router/privateRouter';
import LoginPage from './components/login/loginPage';
import customCreateTheme from './Theme';
import AboutThisPage from './components/aboutThisPage/aboutThisPage';
import DeveloperPage from './components/developerPage/developerPage';

const theme = customCreateTheme();

class App extends React.Component {

  render() {
    return (
      <>
        <ThemeProvider theme={ theme }>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path='/login' element={ <LoginPage/> }/>
              <Route path='/' element={
                <PrivateRoute path='/'>
                  <MainPage />
                </PrivateRoute>
              }/>
              <Route path='/access' element={
                <PrivateRoute path='/access'>
                  <AccessPage />
                </PrivateRoute>
              }/>
              <Route path='/aboutthispage' element={
                <PrivateRoute path='/aboutthispage'>
                  <AboutThisPage />
                </PrivateRoute>
              }/>
              <Route path='/fordeveloperpage' element={
                <PrivateRoute path='/fordeveloperpage'>
                  <DeveloperPage />
                </PrivateRoute>
              }/>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </>
    );
  }
}

export default App;
