import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Divider
} from '@mui/material';
import Layout from '../layout/layout';

class AccessPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
    	<>
        <Layout title='各所へのアクセス' theme='dense' />
        <Grid container direction='column' alignItems='center' justifyContent='flex-start' spacing={5} sx={{ pt: 3 }}>
          <Grid item>
            <Card sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
              <CardHeader
                title='挙式・披露宴会場'
                titleTypographyProps={{ variant: 'access_title' }}
              />
              <Divider />
              <CardContent>
                <Typography variant='access_sub_title'>
                  ◼️ 旧桜宮公会堂<br/>
                </Typography>
                <Typography variant='access_body'>
                  住所: 大阪府大阪市北区天満橋1丁目1番1号　　　　　　　　　<br/>
                  TEL: (06)-6881-3636<br/>
                  Web: <a href='https://produce.novarese.jp/kyusakuranomiya-kokaido/'>Webサイトリンク</a>
                </Typography>
              </CardContent>
              <CardMedia
                component='img'
                image={ process.env.PUBLIC_URL + '/images/kyusakuranomiyakoukaidou.jpg' }
                alt='kyusakuranomiyakoukaidou'
              />
              <Divider />
              <CardContent>
                <Typography variant='access_sub_title'>
                  ◼️ アクセス<br/>
                </Typography>
                <Typography variant='access_body'>
                  ・JR大阪環状線<br/>
                  「桜ノ宮駅」西口より徒歩約9分<br/>
                  ・大阪メトロ堺筋線・谷町線<br/>
                  「南森町駅」3番出口より徒歩約10分<br/>
                  ・JR東西線<br/>
                  「大阪天満宮駅」9番出口より徒歩約9分<br/><br/>
                  皆様にはご面倒をおかけいたしますが<br/>
                  公共交通機関でのご来場をお願いいたします。<br/>
                  なお、新大阪駅と東梅田駅からシャトルバスを運行しますので、<br/>
                  ご利用の方は以下をご確認ください。
                </Typography>
              </CardContent>
            </Card>
          </Grid>  
          <Grid item>
            <Card sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
              <CardHeader
                title='シャトルバス'
                titleTypographyProps={{ variant: 'access_title' }}
              />
              <Divider />
              <CardContent>
                <Typography variant='access_sub_title'>
                  ◼️ 新大阪駅乗り場<br/>
                </Typography>
                <Typography variant='access_body'>
                  発車時刻: 14:35
                </Typography>
              </CardContent>
              <CardMedia
                component='img'
                image={ process.env.PUBLIC_URL + '/images/shuttlebus-shinosaka.png' }
                alt='shuttlebus-shinosaka'
              />
              <Divider />
              <CardContent>
                <Typography variant='access_sub_title'>
                  ◼️ 東梅田駅乗り場<br/>
                </Typography>
                <Typography variant='access_body'>
                  発車時刻: 15:15
                </Typography>
              </CardContent>
              <CardMedia
                component='img'
                image={ process.env.PUBLIC_URL + '/images/shuttlebus-higashiumeda.png' }
                alt='shuttlebus-higashiumeda'
              />
            </Card>
          </Grid>
          <Grid item>
            <Box sx={{ pb: 10 }}/>
          </Grid>
        </Grid>
			</>
    );
  };
}

export default AccessPage;