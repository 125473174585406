import React from 'react';
import {
  Button,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Paper
} from '@mui/material';
import LoginServices from '../../services/loginServices';
import ErrorDialog from '../../error/errorDialog';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      form_button: {
        margin: 2,
        borderRadius: 2,
        height:50,
        width: 170,
        boxShadow: 5
      }
    };
    this.handleName = this.handleName.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
  }

  handleName(event) {
    this.setState({ name: event.currentTarget.value });
  }

  handlePassword(event) {
    this.setState({ password: event.currentTarget.value });
  }

  login() {
    try{
      LoginServices.login({
        user_name: this.state.name.replace(/\s/g, ''),
        password: this.state.password,
      }).then(result => {
        if(result) {
          window.location.href = '/';
        } else {
          this.errorDialog.openDialog();
        }
      });
    } catch(e) {
      this.errorDialog.openDialog();
    }
  }

  render() {
    return(
    	<>
        <Grid container direction='column' alignItems='center' justifyContent='center' sx={{ pt: 15 }}>
          <Grid item>
            <Paper sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
              <Grid container direction='column' alignItems='center' justifyContent='center' spacing={4}>
                <Grid item>
                  <FormLabel>
                    <Typography variant='login_body'>
                      氏名とパスワードの入力をお願いします
                    </Typography>
                  </FormLabel>
                </Grid>
                <Grid item>
                  <TextField
                    label='氏名(漢字名)'
                    id='first_name'
                    onChange={ (e) => this.handleName(e) }
                    sx={{ maxWidth: '300px', width: window.innerWidth * 0.6 }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label='パスワード'
                    type='password'
                    id='password'
                    onChange={ (e) => this.handlePassword(e) }
                    sx={{ maxWidth: '300px', width: window.innerWidth * 0.6 }}
                  />
                </Grid>
                <Grid item sx={{ pb: 5 }}>
                  <Button
                    variant='login_button'
                    size='large'
                    onClick={ () => this.login() }
                    sx={{
                      mx: this.state.form_button.margin,
                      borderRadius: this.state.form_button.borderRadius,
                      height: this.state.form_button.height,
                      width: this.state.form_button.width,
                      boxShadow: this.state.form_button.boxShadow
                    }}
                  >
                    ログイン
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <ErrorDialog
          error_message={ 'ログインに失敗しました。' }
          onClose={ (e) => this.errorDialog.closeDialog() }
          ref={ instance => { this.errorDialog = instance } }
        />
			</>
    );
  };
}

export default LoginPage;