import React from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Grid,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Link,
  Typography
} from '@mui/material';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import ClearIcon from '@mui/icons-material/Clear';

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menu: {
        open: false
      },
      menu_item: {
        height: 50
      },
      title: '',
      style: {}
    }
    this.handleMenuDrawer = this.handleMenuDrawer.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    if(this.props.title !== undefined) {
      this.setState({ title: this.props.title });
    }
    if(this.props.theme === 'dense') {
      this.setState({ style: { color: '#FFFBDC' }});
    } else {
      this.setState({ style: { background: 'transparent', boxShadow: 'none' }});
    }
  }

  handleMenuDrawer(flag) {
    this.setState({ menu: { open: flag }});
  }

  handleLogout() {
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  render() {
    return(
      <>
        <AppBar position='fixed' style={ this.state.style } color='main_color'>
          <Toolbar style={ this.state.style } disableGutters>
            <Grid container direction='row' alignItems='center' justifyContent='center'>
              <Grid item xs></Grid>
              <Grid item xs={6}>
                <Grid container direction='row' alignItems='center' justifyContent='center'>
                  <Typography variant='appbar_title'>
                    { this.state.title }
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction='row-reverse' alignItems='center' justifyContent='flex-start' spacing={2}>
                  <Grid item></Grid>
                  <Grid item>
                    <IconButton aria-label='drawer' color='black' onClick={() => this.handleMenuDrawer(true)}>
                      <MenuSharpIcon style={{ width: 40, height: 40, padding: 0 }}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
          <SwipeableDrawer
            anchor='right'

            open={ this.state.menu.open }
            onOpen={ () => this.handleMenuDrawer(true) }
            onClose={ () => this.handleMenuDrawer(false) }
            PaperProps={{
              sx: { width: '100%', backgroundColor: '#FFFBDC', opacity: '100%' },
            }}
          >
            <Box>
              <List>
                <ListItem key='bar' sx={{ height: this.state.menu_item.height }}>
                  <Grid container direction='row' alignItems='center' justifyContent='right'>
                    <Grid item>
                    <IconButton aria-label='clear' color='primary' size='large' onClick={() => this.handleMenuDrawer(false)}>
                      <ClearIcon fontSize='inherit'/>
                    </IconButton>
                    </Grid>    
                  </Grid>
                </ListItem>
                <Divider />
                <ListItem key='home'>
                  <ListItemButton container={ Link } to='/'>
                    <Grid container direction='row' alignItems='center' justifyContent='center' spacing={5}>
                      <Grid item>
                        <ListItemText primary='ホーム' primaryTypographyProps={{ variant: 'drawer_list' }}/>
                      </Grid>    
                    </Grid>
                  </ListItemButton>
                </ListItem>
                <ListItem key='access'>
                  <ListItemButton container={ Link } to='/access'>
                    <Grid container direction='row' alignItems='center' justifyContent='center' spacing={5}>
                      <Grid item>
                        <ListItemText primary='アクセス' primaryTypographyProps={{ variant: 'drawer_list' }}/>
                      </Grid>    
                    </Grid>
                  </ListItemButton>
                </ListItem>
                <ListItem key='aboutthispage'>
                  <ListItemButton container={ Link } to='/aboutthispage'>
                    <Grid container direction='row' alignItems='center' justifyContent='center' spacing={5}>
                      <Grid item>
                        <ListItemText primary='本ページについて' primaryTypographyProps={{ variant: 'drawer_list' }}/>
                      </Grid>    
                    </Grid>
                  </ListItemButton>
                </ListItem>
                <ListItem key='fordeveloperpage'>
                  <ListItemButton container={ Link } to='/fordeveloperpage'>
                    <Grid container direction='row' alignItems='center' justifyContent='center' spacing={5}>
                      <Grid item>
                        <ListItemText primary='開発手記' primaryTypographyProps={{ variant: 'drawer_list' }}/>
                      </Grid>    
                    </Grid>
                  </ListItemButton>
                </ListItem>
                <ListItem key='logout'>
                  <ListItemButton onClick={ (e) => this.handleLogout() }>
                    <Grid container direction='row' alignItems='center' justifyContent='center' spacing={5}>
                      <Grid item>
                        <ListItemText primary='ログアウト' primaryTypographyProps={{ variant: 'drawer_list' }}/>
                      </Grid>    
                    </Grid>
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </SwipeableDrawer>
        </AppBar>
        <Toolbar />
      </>
    );
  };
}

export default Layout;