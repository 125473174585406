import React from 'react';
import {
	Grid,
	Card,
  CardHeader,
	CardMedia,
	CardContent,
	Typography,
  Divider,
  IconButton
} from '@mui/material';
import Layout from '../layout/layout';

class DeveloperPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
      icon_size: 32
		};
	}

	render() {
		return (
      <>
        <Layout title='開発手記' theme='dense' />
        <Grid container direction='column' alignItems='center' justifyContent='flex-start' spacing={3} sx={{ pt: 5, pb: 5 }}>
          <Grid item>
            <Card sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
              <CardHeader
                title='はじめに'
                titleTypographyProps={{ variant: 'access_title' }}
              />
              <Divider />
              <CardContent>
                <Grid container direction='column' alignItems='center' justifyContent='flex-start' spacing={3}>
                  <Grid item>
                    <Typography variant='main_body'>
                      このWeb招待状を作成した柏木健登です。<br/><br/>
                      開発者の皆さんはどちらかというと中身の方が興味あるかなと思いこのページを作成させていただきました。<br/><br/>
                      そのためこの開発手記については私が勝手に書かせていただいております。(笑)<br/>
                      これ以降はどのような構成で設計を行なって、どのようなところで詰まったのかつらつらと書いていきたいと思います。<br/>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>            
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
              <CardHeader
                title='システム構成'
                titleTypographyProps={{ variant: 'access_title' }}
              />
              <Divider />
              <CardContent>
                <Grid container direction='column' alignItems='left' justifyContent='flex-start' spacing={3}>
                  <Grid item>
                    <Typography variant='main_body'>
                      今回については、<br/>
                      ・利用対象人数が少数であることと<br/>
                      ・頻繁な利用が見込まれないことから<br/>
                      サーバレスを大方針としてシステム構成を考えました。<br/>
                      以下が構成図になります。(小さくてすいません)<br/>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CardMedia
                      component='img'
                      image={process.env.PUBLIC_URL + '/images/system-diagram.png'}
                      alt='system-diagram'
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant='main_body'>
                      フロントエンドフレームワークはReactを採用しました。<br/>
                      採用理由は単純に開発期間も短く正確なものを作る必要があったため一番使ってきたフレームワークを選定しました。<br/><br/>
                      バックエンドも同じ理由でPythonを言語として選択しました。<br/>
                      さらにバックエンドに関しては、データベースも複雑なものではないかつ開発スピードを重視する必要があったため、丸っとパッケージになってるAWS SAMを利用して開発をすすめました。<br/><br/>
                      CI/CDツールについては初挑戦でしたがGihub Actionsを採用しました。普段はCircleCIを使っています。<br/>
                      今回はテストも組み込まれてない至極単純なパイプラインでしたので、サービスを下手に増やしたくないのもあってGithub Actionsを選びました。<br/>
                      (残りは長くなるので省きます(笑))
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
              <CardHeader
                title='詰まったところ'
                titleTypographyProps={{ variant: 'access_title' }}
              />
              <Divider />
              <CardContent>
                <Grid container direction='column' alignItems='center' justifyContent='flex-start' spacing={3}>
                  <Grid item>
                    <Typography variant='main_body'>
                      主に詰まったところを上げていきます。<br/><br/>
                      まずはAWS SAMです。<br/>
                      sam local start-apiコマンドについて　非同期な仮想サーバとして動かしたいと思ったのでコンテナ化しようと思ったらうまくできませんでした。<br/>
                      MacBookを開発端末として利用してますが　MacはDockerのhostネットワークモードが利用できないため　内部のLambdaコンテナとうまく通信ができませんでした。<br/><br/>
                      フロントエンド部分についてはreact-router-domでログイン認証部分を実装しているのですがPrivateRouteコンポーネントをカスタムで作成したのですが
                      クラスコンポーネントでは作成できずこの部分だけ関数コンポーネントで記載することになりました。<br/>
                      その影響もあってかnavigate関数がうまく使えませんでした、その代わりにwindow.location.hrefを利用しました。<br/>
                      ここは上手いやり方がありましたらご教授いただけますと幸いです。<br/><br/>
                      他も色々ありますが以上とさせていただきます。
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>            
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
              <CardHeader
                title='終わりに'
                titleTypographyProps={{ variant: 'access_title' }}
              />
              <Divider />
              <CardContent>
                <Grid container direction='column' alignItems='center' justifyContent='flex-start' spacing={3}>
                  <Grid item>
                    <Typography variant='main_body'>
                      長くなってしまい申し訳ないのですが、この度の開発経験はとても貴重な経験となりました。<br/>
                      他にも今Flutterで自作アプリを作ったりなど様々なことにチャレンジしているのでこれからも頑張っていきたいと思います。<br/>
                      皆様これからもよろしくおねがいいたします。
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Typography variant='main_body'>
                  ◼️ アクセスマップ
                </Typography>
                <Grid container direction='row' alignItems='center' justifyContent='flex-start' spacing={1  }>
                  <Grid item>
                    <IconButton href='https://github.com/tuimac'>
                      <img
                        src={ process.env.PUBLIC_URL + '/images/github.png' }
                        width={ this.state.icon_size }
                        height={ this.state.icon_size }
                        alt='github'
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton href='https://tuimac.medium.com/'>
                      <img
                        src={ process.env.PUBLIC_URL + '/images/medium.png' }
                        width={ this.state.icon_size }
                        height={ this.state.icon_size }
                        alt='medium'
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton href='https://twitter.com/tuimac6b6b'>
                      <img
                        src={ process.env.PUBLIC_URL + '/images/x.png' }
                        width={ this.state.icon_size * 0.85 }
                        height={ this.state.icon_size * 0.85 }
                        alt='x'
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>            
          </Grid>
        </Grid>
			</>
		);
	};
}

export default DeveloperPage;