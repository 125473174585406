import React from 'react';
import {
	Box,
	Fade,
	Button,
	Grid,
	Card,
	CardHeader,
	CardMedia,
	CardContent,
	Divider,
	Typography
} from '@mui/material';
import Layout from '../layout/layout';
import AattendanceDialog from './attendanceDialog';
import LoadingPage from '../loadingPage/loadingPage';
import GuestsServices from '../../services/guestsServices';
import ShowanswerDialog from './showAnswer';

class MainPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		this.setState({
			user_name: JSON.parse(atob(localStorage.getItem('token')))['user_name']
		}, () => {
			GuestsServices.getGuestsInfo(this.state.user_name).then(user_info => {
				this.setState({ answer: user_info });
				if (user_info.hasOwnProperty('is_answer')) {
					if (user_info['is_answer']['BOOL']) {
						this.setState({ is_answer: true }, () => this.setState({ loading: false }));
						return;
					}
				}
				this.setState({ is_answer: false }, () => this.setState({ loading: false }));
			});
		});
	}

	render() {
		return (
			<>
				{
					this.state.loading
						? <LoadingPage />
						: <>
								<Layout />
								<Grid container direction='column' alignItems='center' justifyContent='flex-start' spacing={5}>
									<Grid item>
										<Card sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
											<Fade in={true} timeout={4000}>
												<CardMedia
													component='img'
													image={process.env.PUBLIC_URL + '/images/main.png'}
													alt='main'
												/>
											</Fade>
											<CardContent>
												<Grid container direction='column' alignItems='center' justifyContent='flex-start' spacing={0}>
													<Grid item>
														<Typography variant='main_body'>
															<center>
																皆様いかがお過ごしでしょうか<br />
																この度　私ども二人は<br />
																結婚式を挙げることになりました<br />
																つきましてはご多用中誠に恐縮ではございますが<br />
																ささやかな宴を催したいと存じますので<br />
																ご出席くださいますようご案内申し上げます<br /><br />
															</center>
														</Typography>
														<Typography variant='main_body'>
															<center>
																2024年豊月吉日<br /><br />
															</center>
														</Typography>
														<Typography variant='main_body'>
															<center>
																柏木　健登<br />
																豆田　和穂
															</center>
														</Typography>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
									<Grid item>
										<Card sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
											<CardHeader
												title='挙式・披露宴詳細'
												titleTypographyProps={{ variant: 'main_title' }}
											/>
											<Divider />
											<CardContent>
												<Grid container direction='column' alignItems='center' justifyContent='center' spacing={0}>
													<Grid item>
														<Box>
															<Typography variant='main_body'>
																日　時　2024年3月9日（土）<br />
																受　付　15時15分<br />
																挙　式　16時00分<br />
																披露宴　16時30分<br />
															</Typography>
														</Box>
													</Grid>
												</Grid>
											</CardContent>
											<Divider />
											<CardContent>
												<Grid container direction='column' alignItems='center' justifyContent='center' spacing={0}>
													<Grid item>
														<Box>
															<Typography variant='main_body'>
																場　所　旧桜宮公会堂　　　<br />
															</Typography>
														</Box>
													</Grid>
													<Grid item>
														<Button
															variant='detail_button'
															sx={{ borderRadius: 5, height: 20, width: 116 }}
															onClick={() => window.window.location.href = '/access'}
														>
															<Typography variant='main_detail_button'>
																詳しくはこちら
															</Typography>
														</Button>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
									<Grid item>
										<Grid container direction='column' alignItems='center' justifyContent='center' spacing={0}>
											<Grid item>
													<Typography variant='main_body' align='center'>
														<Box>
															誠に恐れ入りますが当日の結婚式に<br/>
															ご列席をおねがいしたく存じますので<br/>
															<strong>15時40分</strong>までにお越しくださいますよう<br/>
															お願い申し上げます
														</Box>
													</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid container direction='column' alignItems='center' justifyContent='center' spacing={0}>
											<Grid item>
												<Box>
													<Typography variant='main_body' align='center'>
														またお手数ながらご都合のほどを<strong>2月3日</strong>までに<br />
														ご一報賜りますようお願い申し上げます
													</Typography>
												</Box>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Button
											variant='attendance_button'
											sx={{ borderRadius: 50, height: 70, width: 200, boxShadow: 5 }}
											onClick={() => {
												this.state.is_answer ? this.showanswerDialog.openDialog() : this.attendanceDialog.openDialog();
											}}
										>
											<Typography variant='main_attendance_button'>
												{
													this.state.is_answer ? '回答内容照会' : '出欠席回答'
												}
											</Typography>
										</Button>
									</Grid>
									<Grid item></Grid>
								</Grid>
								<AattendanceDialog
									user_name={ this.state.user_name }
									onClose={() => this.attendanceDialog.closeDialog()}
									ref={instance => { this.attendanceDialog = instance }}
								/>
								<ShowanswerDialog
									answer={ this.state.answer }
									onClose={() => this.showanswerDialog.closeDialog()}
									ref={instance => { this.showanswerDialog = instance }}
								/>
							</>
				}
			</>
		);
	};
}

export default MainPage;