import axios from 'axios';

class LoginServices {

  static isLogin(auth_info) {
    let url = process.env.REACT_APP_BACKEND_URL + '/islogin';
    return axios.post(url, auth_info, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
  }

  static login(token) {
    let url = process.env.REACT_APP_BACKEND_URL + '/login';
    return axios.post(url, token, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.data.token !== undefined) {
        localStorage.setItem('token', response.data.token);
      } else {
        return false;
      }
      return true;
    })
    .catch(error => {
      return false;
    });
  }
}

export default LoginServices;