import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export default function customCreateTheme() {
  return responsiveFontSizes(createTheme({
    palette: {
      background: {
        default: '#FFFBDC'
      },
      main_color: {
        main: '#FFFBDC'
      }
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'detail_button' },
            style: {
              fontSize: 9.5,
              color: '#00000',
              backgroundColor : '#FF9130',
              alignItems: 'center'
            }
          },
          {
            props: { variant: 'login_button' },
            style: {
              fontSize: 13,
              fontWeight: 'bold',
              fontFamily: 'Zen Old Mincho',
              color: '#5B5959',
              backgroundColor : '#FFFCBF'
            }
          },
          {
            props: { variant: 'attendance_button' },
            style: {
              fontSize: 14,
              fontWeight: 'bold',
              color: '#5B5959',
              backgroundColor : '#EAF4FF'
            }
          },
          {
            props: { variant: 'form_forward_button' },
            style: {
              fontSize: 13,
              color: '#00000',
              backgroundColor : '#FFFCBF',
              alignItems: 'center'
            }
          },
          {
            props: { variant: 'form_backward_button' },
            style: {
              fontSize: 13,
              color: '#00000',
              backgroundColor : '#E5E1DA',
              alignItems: 'center'
            }
          },
        ],
      },
      MuiInputLabel: {
        styleOverrides: {
          formControl: {
            fontSize: 14,
            fontFamily: 'Zen Old Mincho'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          formControl: {
            fontSize: 14,
            fontFamily: 'Zen Old Mincho'
          }
        }
      }
    },
    typography: {
      fontFamily: ['Zen Old Mincho'],
      allVariants: {
        color: 'black'
      },
      main_title: {
        fontSize: 18,
        fontWeight: 'bold',
        fontFamily: 'Zen Old Mincho'
      },
      main_body: {
        fontSize: 13,
        fontFamily: 'Zen Old Mincho'
      },
      main_attendance_button: {
        fontSize: 15,
        fontFamily: 'Zen Old Mincho'
      },
      main_detail_button: {
        fontSize: 10,
        fontFamily: 'Zen Old Mincho'
      },
      login_body: {
        fontSize: 14,
        fontFamily: 'Zen Old Mincho'
      },
      attendance_dialog_title: {
        fontSize: 16,
        fontFamily: 'Zen Old Mincho'
      },
      attendance_dialog_body: {
        fontSize: 14,
        fontFamily: 'Zen Old Mincho'
      },
      drawer_list: {
        fontSize: 17,
        fontFamily: 'Zen Old Mincho'
      },
      appbar_title: {
        fontSize: 20.5,
        fontWeight: 'bold',
        fontFamily: 'Zen Old Mincho',
        color: 'black'
      },
      access_title: {
        fontSize: 19,
        fontWeight: 'bold',
        fontFamily: 'Zen Old Mincho'
      },
      access_sub_title: {
        fontSize: 17,
        fontWeight: 'bold',
        fontFamily: 'Zen Old Mincho'
      },
      access_body: {
        fontSize: 14,
        fontFamily: 'Zen Old Mincho'
      },
      error_title: {
        fontSize: 21,
        fontFamily: 'Zen Old Mincho'
      },
      error_body: {
        fontSize: 13,
        color: 'red',
        fontFamily: 'Zen Old Mincho'
      },
      registered_body: {
        fontSize: 14,
        fontFamily: 'Zen Old Mincho'
      }
    }
  }));
}