import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
  Typography,
  Divider,
  Paper,
  List,
  ListItemText
} from '@mui/material';
import GuestsServices from '../../services/guestsServices';
import ErrorDialog from '../../error/errorDialog';
import RegisteredDialog from './registeredDialog';

class ConfirmAnswerDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      error_message: ''
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.sendAnswer = this.sendAnswer.bind(this);
  }

  openDialog() {
    this.setState({ open: true });
  }

  closeDialog() {
    this.setState({ open: false });
  }

  sendAnswer(answers) {
    try {
      answers['name'] = this.props.answers.name;
      this.setState({ loading: true })
      GuestsServices.sendAttendance(answers).then(result => {
        if(result) {
          this.setState({ loading: false });
          this.registeredDialog.openDialog();
        } else {
          this.setState({ loading: false });
          this.setState({ error_message: '回答が正常に登録されませんでした。' });
          this.errorDialog.openDialog();
        }
      });
      this.props.closeAttendanceDialog();
      this.closeDialog();
    } catch(e) {
      this.setState({ error_message: e.message });
      this.errorDialog.openDialog();
    }
  }

  render() {
    return(
    	<>
        <Dialog
          open={ this.state.open }
          onClose={ (e) => this.closeDialog() }
          aria-labelledby='confirm-dialog-title'
          aria-describedby='confirm-dialog-body'
          scroll='paper'
          PaperProps={{
            sx: {
              height: '70%',
              width: '80%'
            }
          }}
        >
          <DialogTitle id='dialog-title' sx={{ textAlign: 'center' }}>
            <Typography variant='attendance_dialog_title'>
              回答内容確認
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <FormLabel id='attendance'>
              <Typography variant='attendance_dialog_body' align='center'>
                以下の回答内容で送信しますが、<br/>
                よろしいでしょうか
              </Typography>
            </FormLabel>
            <Box sx={{ height: 50 }} />
            <Paper elevation={ 12 } sx={{ pl: 2 }}>
              <List>
                <ListItemText>
                  <Typography variant='attendance_dialog_body' align='left'>
                    出欠席: { this.props.answers.attendance.answer ? 'はい' : 'いいえ' }
                  </Typography>
                </ListItemText>
                <ListItemText>
                  <Typography variant='attendance_dialog_body' align='left'>
                    アレルギー有無: { this.props.answers.allergy.answer ? 'はい' : 'いいえ' }
                  </Typography>
                </ListItemText>
                <ListItemText>
                  <Typography variant='attendance_dialog_body' align='left'>
                    { this.props.answers.allergy.answer ? `アレルギー内容: ${this.props.answers.allergy.content}` : '' }
                  </Typography>
                </ListItemText>
              </List>
            </Paper>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }} sx={{ mb: 5 }}>
            <Button
              variant='form_backward_button'
              size='large'
              color='close_button'
              onClick={ () => this.closeDialog() }
              sx={{
                mx: this.props.form_button.margin,
                borderRadius: this.props.form_button.borderRadius,
                height: this.props.form_button.height,
                width: this.props.form_button.width,
                boxShadow: this.props.form_button.boxShadow
              }}
            >
              戻る
            </Button>
            <Button
              variant='form_forward_button'
              size='large'
              color='form_button'
              onClick={ () => this.sendAnswer(this.props.answers) }
              sx={{
                mx: this.props.form_button.margin,
                borderRadius: this.props.form_button.borderRadius,
                height: this.props.form_button.height,
                width: this.props.form_button.width,
                boxShadow: this.props.form_button.boxShadow
              }}
            >
                回答送信
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={ this.state.loading }
        >
          <CircularProgress color='inherit' size={ 80 } />
        </Backdrop>
        <ErrorDialog
          error_message={ this.state.error_message }
          onClose={ (e) => this.errorDialog.closeDialog() }
          ref={ instance => { this.errorDialog = instance } }
        />
        <RegisteredDialog
          onClose={ (e) => this.registeredDialog.closeDialog() }
          ref={ instance => { this.registeredDialog = instance } }
        />
			</>
    );
  };
}

export default ConfirmAnswerDialog;