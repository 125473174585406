import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Typography,
  Divider,
  TextField,
  Grow
} from '@mui/material';
import ConfirmAnswerDialog from './confirmAnswerDialog';

class AattendanceDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isFormValid: false,
      attendance: {
        answer: false,
        checked: ''
      },
      allergy: {
        answer: false,
        checked: ''
      },
      allergy_content: '',
      form_button: {
        margin: 2,
        borderRadius: 2,
        height:50,
        width: 170,
        boxShadow: 5
      }
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleAttendanceAnswer = this.handleAttendanceAnswer.bind(this);
    this.handleAllergyAnswer = this.handleAllergyAnswer.bind(this);
    this.handleAllergyContent = this.handleAllergyContent.bind(this);
    this.showConfirmAnswer = this.showConfirmAnswer.bind(this);
  }

  openDialog() {
    this.setState({ open: true });
  }

  closeDialog() {
    this.setState({ open: false });
  }

  handleAttendanceAnswer(event) {
    this.setState({ attendance: { answer: event.currentTarget.value === 'true', checked: 'yes' }});
  }

  handleAllergyAnswer(event) {
    this.setState({ allergy: { answer: event.currentTarget.value === 'true', checked: 'yes' }});
  }

  handleAllergyContent(event) {
    this.setState({ allergy_content: event.currentTarget.value });
  }

  showConfirmAnswer() {
    this.confirmDialog.openDialog();
  }

  render() {
    return(
    	<>
        <Dialog
          open={ this.state.open }
          onClose={ (e) => this.closeDialog() }
          aria-labelledby='dialog-title'
          aria-describedby='dialog-body'
          scroll='paper'
          PaperProps={{
            sx: {
              height: '70%',
              width: '80%'
            }
          }}
        >
          <DialogTitle id='dialog-title' sx={{ textAlign: 'center' }}>
            <Typography variant='dialogTitle'>
              結婚式出欠席回答
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <FormControl fullWidth>
              <FormLabel id='attendance'>
                <Typography variant='attendance_dialog_body' align='center'>
                  出欠席の選択をお願いいたします
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby='attendance'
                name='attendance'
                style={{ justifyContent: 'space-evenly'}}
                onChange={ (e) => this.handleAttendanceAnswer(e) }
              >
                <FormControlLabel value={ true } control={ <Radio /> } label={ <Typography variant='attendance_dialog_body'>出席</Typography> } />
                <FormControlLabel value={ false } control={ <Radio /> } label={ <Typography variant='attendance_dialog_body'>欠席</Typography> } />
              </RadioGroup>
            </FormControl>
            <Grow in={ this.state.attendance.answer }>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <FormLabel id='allergy'>
                  <Typography variant='attendance_dialog_body' align='left'>
                    アレルギー有無の選択をお願いいたします
                  </Typography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='allergy'
                  name='allergy'
                  style={{ justifyContent: 'space-evenly'}}
                  onChange={ (e) => this.handleAllergyAnswer(e) }
                >
                  <FormControlLabel value={ false } control={ <Radio /> } label={ <Typography variant='attendance_dialog_body'>なし</Typography> } />
                  <FormControlLabel value={ true } control={ <Radio /> } label={ <Typography variant='attendance_dialog_body'>あり</Typography> } />
                </RadioGroup>
              </FormControl>
            </Grow>
            <Grow in={ this.state.allergy.answer }>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <FormLabel id='allergy-content-label'>
                  <Typography variant='attendance_dialog_body' align='left'>
                    アレルギーをお持ちの場合はアレルギー対象の記載をお願いいたします
                  </Typography>
                </FormLabel>
                <TextField
                  fullWidth
                  label='アレルギーの種類'
                  id='allergy-content'
                  sx={{ mt: 3 }}
                  helperText='(記入例) 卵、乳製品、小麦、えび、かに等'
                  onChange={ (e) => this.handleAllergyContent(e) }
                />
              </FormControl>
            </Grow>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }} sx={{ mb: 5 }}>
            {
              this.state.attendance.checked === 'yes' && this.state.allergy.checked === 'yes' ? (
                <>
                  <Button
                    variant='form_backward_button'
                    size='large'
                    onClick={ () => this.closeDialog() }
                    sx={{
                      mx: this.state.form_button.margin,
                      borderRadius: this.state.form_button.borderRadius,
                      height: this.state.form_button.height,
                      width: this.state.form_button.width,
                      boxShadow: this.state.form_button.boxShadow
                    }}
                  >
                    閉じる
                  </Button>
                  <Button
                    variant='form_forward_button'
                    size='large'
                    onClick={ () => this.showConfirmAnswer() }
                    sx={{
                      mx: this.state.form_button.margin,
                      borderRadius: this.state.form_button.borderRadius,
                      height: this.state.form_button.height,
                      width: this.state.form_button.width,
                      boxShadow: this.state.form_button.boxShadow
                    }}
                  >
                      回答内容確認
                  </Button>
                </>
              ) : (
                <Button
                  variant='form_forward_button'
                  size='large'
                  onClick={ () => this.closeDialog() }
                  sx={{
                    mx: this.state.form_button.margin,
                    borderRadius: this.state.form_button.borderRadius,
                    height: this.state.form_button.height,
                    width: this.state.form_button.width,
                    boxShadow: this.state.form_button.boxShadow
                  }}
                >
                  閉じる
                </Button>
              )
            }
          </DialogActions>
        </Dialog>
        <ConfirmAnswerDialog
          onClose={ (e) => this.confirmDialog.closeDialog() }
          ref={ instance => { this.confirmDialog = instance } }
          closeAttendanceDialog={ (e) => this.closeDialog() }
          form_button={ this.state.form_button }
          answers={{
            user_name: this.props.user_name,
            attendance: { answer: this.state.attendance.answer },
            allergy: { answer: this.state.allergy.answer, content: this.state.allergy_content },
            timeout: 60
          }}
        />
			</>
    );
  };
}

export default AattendanceDialog;