import React from 'react';
import {
	Grid,
	Card,
	CardMedia,
	CardContent,
	Typography
} from '@mui/material';
import Layout from '../layout/layout';

class AboutThisPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		return (
      <>
        <Layout title='本ページについて' theme='dense' />
        <Grid container direction='column' alignItems='center' justifyContent='flex-start' spacing={5} sx={{ pt: 5 }}>
          <Grid item>
            <Card sx={{ maxWidth: '600px', width: window.innerWidth * 0.9 }}>
              <CardContent>
                <Grid container direction='column' alignItems='center' justifyContent='flex-start' spacing={3}>
                  <Grid item>
                    <CardMedia
                      component='img'
                      image={process.env.PUBLIC_URL + '/images/kento_single.jpg'}
                      alt='kento_single'
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant='main_body'>
                      本ページ作者の柏木健登です。<br/><br/>
                      実はこのWeb招待状、サーバの用意からプログラムも含め全て1から私が作成しました。<br/>
                      皆様に楽しんでもらうためにこだわりを入れたいと思い、このWeb招待状を作成しました。<br/>
                      短い作成期間でしたが、私なりに良いWeb招待状が作成できたと思います。<br/><br/>
                      結婚式自体に関しても、皆様をお迎えするにあたり素晴らしい宴にするために2人で準備していますので<br/>
                      楽しみにしていてください!!<br/>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CardMedia
                      component='img'
                      image={process.env.PUBLIC_URL + '/images/kento_kazuho.jpg'}
                      alt='kento_single'
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item></Grid>
        </Grid>
			</>
		);
	};
}

export default AboutThisPage;